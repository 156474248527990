$(document).on("turbolinks:load", function() {
  jQuery(function ($) {
    $("span.product-history").click(function() {
      $.get("/freepeople/product_history?subdir=" + $(this).data("subdir"), function(data) {
        let history = data.history.map(ph => (
          `<tr>
            <th scope="row">${ph.date}</th>
            <td>${ph.likes}</td>
            <td>${ph.reviews}</td>
            <td>${ph.rating}</td>
          </tr>`
        )).join("");
        
        $(".modal-body").html(
        `<div class="container-fluid">
          <div class="row">
            <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
              <img src=${data.image_url} class="img-fluid"></img>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Likes</th>
                    <th scope="col">Reviews</th>
                    <th scope="col">Rating</th>
                  </tr>
                </thead>
                <tbody>
                  ${history}
                </tbody>
              </table>
            </div>
          </div>
        </div>`
        );

        $(".modal-title").text(`${data.name}`);

      });
    });
  });
})
