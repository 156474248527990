document.addEventListener("turbolinks:load", function() {
  jQuery(function ($) {
    $(".sidebar-dropdown > a").click(function() {
      $(".sidebar-submenu").slideUp(200);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } 
      else {
        $(".sidebar-dropdown").removeClass("active");
        $(this).next(".sidebar-submenu").slideDown(200);
        $(this).parent().addClass("active");
      }
    });

    $("#close-sidebar").click(function() {
      $(".page-wrapper").removeClass("toggled");
    });
  
    $("#show-sidebar").click(function() {
      $(".page-wrapper").addClass("toggled");
    });

    $("#sidebar-search").keypress(function(event){
      let keycode = (event.keyCode ? event.keyCode : event.which);
      if(keycode == '13') {
        let search_param = $(this)

        if(search_param != "") {
          window.location = search_param[0].baseURI + "&query=" + search_param.val()
        }
      }
      event.stopPropagation();
    });
  });
})