$(document).on("turbolinks:load", function() {
  function get_product_status(pid) {
    $.ajax({
      type: "GET",
      url: "/faire/product_status",
      data: {pid: pid},
      success: function(res) {
        $(`#status-${pid}`).html(`
          <span class="btn btn-xs btn-${status_code(res.status)}">${res.status}</span>
        `)
      }
    })
  }

  function status_code(status) {
    switch(status) {
      case 'PUBLISHED':
        return "success"
      case 'UNPUBLISHED':
        return "secondary"
      case 'DELETED':
        return "danger"
      default:
        return "danger"
    }
  }
});