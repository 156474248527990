require("bootstrap")
require("./sidebar.js")
require("./fashiongo.js")
require("./faire.js")
require("./freepeople.js")

import "chartkick/chart.js"
import $ from 'jquery';

global.$ = jQuery;

document.addEventListener("turbolinks:load", function() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="popover"]').popover()
    })
})
