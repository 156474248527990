const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

$(document).on("turbolinks:load", function() {
  jQuery(function ($) {
    $("select#sort_option").bind('change', function() {
      let selection = $(this).val();
      if (selection == 'Vendor') {
        $(".price-range").addClass("invisible");
        $(".price-range").removeClass("visible");
      }
      else {
        $(".price-range").addClass("visible");
        $(".price-range").removeClass("invisible");
      }
    });

    $("select#vendor").bind('change', function() {
      let selection = $(this).val();
      if (selection == '') {
        $(".date-range").addClass("invisible");
        $(".date-range").removeClass("visible");
      }
      else {
        $(".date-range").addClass("visible");
        $(".date-range").removeClass("invisible");
      }
    })

    $(".fg-vendor-name").click(function() {
      vendor_id = $(this).data("vendor-id");
      date = $(this).data("date");
      $.get(`/fashiongo/fetch_items?vendor_id=${vendor_id}&date=${date}`, function(data) {
        $(`#vendor-items-${vendor_id}`).html(
          `
          <div class="d-flex flex-row">
            <div class="card card-block border-light shadow-sm">
              <div class="h-100 d-flex flex-column justify-content-between">
                <div class="flex-grow-1">
                  <img src="${data[0].image_url}" class="card-img-top p-1">
                </div>
                <div class="card-body d-flex flex-column justify-content-end px-1 py-1">
                  <div>
                    <a class="text-dark text-bold" href="${data[0].url}" target="_blank">
                      <strong>${data[0].name}</strong>
                    </a>
                  </div>
                  <div class="text-right font-weight-bolder">
                    <strong class="text-muted">${formatter.format(data[0].price)}</strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-block border-light shadow-sm">
              <div class="h-100 d-flex flex-column justify-content-between">
                <div class="flex-grow-1">
                  <img src="${data[1].image_url}" class="card-img-top p-1">
                </div>
                <div class="card-body d-flex flex-column justify-content-end px-1 py-1">
                  <div>
                    <a class="text-dark text-bold" href="${data[1].url}" target="_blank">
                      <strong>${data[1].name}</strong>
                    </a>
                  </div>
                  <div class="text-right font-weight-bolder">
                    <strong class="text-muted">${formatter.format(data[1].price)}</strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-block border-light shadow-sm">
              <div class="h-100 d-flex flex-column justify-content-between">
                <div class="flex-grow-1">
                  <img src="${data[2].image_url}" class="card-img-top p-1">
                </div>
                <div class="card-body d-flex flex-column justify-content-end px-1 py-1">
                  <div>
                    <a class="text-dark text-bold" href="${data[2].url}" target="_blank">
                      <strong>${data[2].name}</strong>
                    </a>
                  </div>
                  <div class="text-right font-weight-bolder">
                    <strong class="text-muted">${formatter.format(data[2].price)}</strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-block border-light shadow-sm">
              <div class="h-100 d-flex flex-column justify-content-between">
                <div class="flex-grow-1">
                  <img src="${data[3].image_url}" class="card-img-top p-1">
                </div>
                <div class="card-body d-flex flex-column justify-content-end px-1 py-1">
                  <div>
                    <a class="text-dark text-bold" href="${data[3].url}" target="_blank">
                      <strong>${data[3].name}</strong>
                    </a>
                  </div>
                  <div class="text-right font-weight-bolder">
                    <strong class="text-muted">${formatter.format(data[3].price)}</strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card-block border-light shadow-sm">
              <div class="h-100 d-flex flex-column justify-content-between">
                <div class="flex-grow-1">
                  <img src="${data[4].image_url}" class="card-img-top p-1">
                </div>
                <div class="card-body d-flex flex-column justify-content-end px-1 py-1">
                  <div>
                    <a class="text-dark text-bold" href="${data[4].url}" target="_blank">
                      <strong>${data[4].name}</strong>
                    </a>
                  </div>
                  <div class="text-right font-weight-bolder">
                    <strong class="text-muted">${formatter.format(data[4].price)}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          `
        );
      });
    });
  });
})
